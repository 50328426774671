import React from 'react';

import Meta from '../components/meta';

import { StaticImage } from "gatsby-plugin-image";

import Contents from '../components/contents';



const Mission = ({data}) =>{

    const smallWidth = 144;
    const largeWidth = 184;

    return(
        <>

            <Meta
                title="ミッション"
                description="しくみを創る企業。言われたものだけを創っていては、お客様のお役に立てません。お客様は何がしたいのか。お客様の目的は何なのか。しくみまで考え、ソリューションとしてご提供します。"
            />

            <Contents position="mission">

              <div className="c-sub-header">
                  <em className="c-sub-header--eng u-josef">
                  MISSION
                  </em>

                  <h1 className="c-sub-header--title">
                  お客様の「成功」をサポート
                  </h1>
              </div>

              <div className="p-ms-grid">
                <div className="p-ms-grid-cell is-tl">
                  <div className="p-ms-grid-cell-inner">
                    <h2 className="p-ms--title is-copy">
                      しくみを創る企業
                    </h2>
                  </div>
                </div>
                <div className="p-ms-grid-cell is-tr">
                  <div className="p-ms-grid-cell-inner">
                    <p className="p-ms--text">
                    システムや商品をお客様にはご提供いたしません。<br />
                    「固定概念」「一般常識」「課題・問題点」など<br />
                    お客様の様々な事業の障害を<br />
                    乗り越えるための様々な「しくみ」をご用意し<br />
                    ソリューションとしてご提供していきます。<br />
                    一緒に悩み、一緒に考え、一緒に創り、一緒に乗り越えて行く。<br />
                    業者ではなく、私たちはそんなパートナーでありたい。<br />
                    私たちはお客様から求め続けられる企業を目指していきます。
                    </p>
                  </div>
                </div>

                <div className="p-ms-grid-cell is-br">
                  <div className="p-ms-grid-cell-inner u-align-center">
                    <h2 className="p-ms--title is-line u-margin-bottom__1">
                      企業理念
                    </h2>

                    <p className="p-ms--text is-large">
                    株式会社アクロスソリューションズは、<br />
                    「固定概念」「一般常識」「課題・問題点」など、<br />
                    お客様の様々な事業の障害を<br />
                    乗り越える<span className="is-caption">(アクロス)</span>お手伝い<span className="is-caption">(ソリューションズ)</span>をすることにより、<br />
                    お客様の事業発展を通じて社会に貢献します。
                    </p>

                    <div className="p-ms-logo">
                      <div className="p-ms-logo-box is-first">
                        <StaticImage
                            src="../images/cp_logo_a.png"
                            alt="across"
                            placeholder="blurred"
                            quality="100"
                            width={smallWidth}
                        />
                      </div>

                      <div className="p-ms-logo-box is-last">
                        <StaticImage
                            src="../images/cp_logo_s.png"
                            alt="solutions"
                            placeholder="blurred"
                            quality="100"
                            width={smallWidth}
                        />
                      </div>

                      <div className="p-ms-logo-box">
                        <StaticImage
                            src="../images/cp_logo_as.png"
                            alt="across solutions"
                            placeholder="blurred"
                            quality="100"
                            width={largeWidth}
                        />
                      </div>
                    </div>

                  </div>
                </div>

              </div>

              <div className="p-mission">

                  <section className="p-mission-box">
                      <h2 className="p-mission-box--title">
                      私たちのミッション
                      </h2>

                      <div className="p-mission-box-wrap">
                        <p className="p-mission-box--text">
                        株式会社アクロスソリューションズは、流通業様に特化したICTソリューションをご提供しております。
                        ICTツールは基幹システムだけではありません。ICTツールやウェブをもっともっと活用し、売上を今以上に上げたい、業務改善をしたい、
                        事業にしくみを組み込みたい、などなど様々な要望があると思います。
                        </p>

                        <p className="p-mission-box--text">
                        ICTツールやウェブには、活用方法や運用方法があります。システムやウェブを導入・採用したからといって、すぐに効果が上がるとは限りません。
                        </p>

                        <p className="p-mission-box--text">
                        ICTツールやウェブはあなたの会社のしくみを創る役割を持っています。
                        戦略立案・マーケティング・ターゲティングなどを同時に実施することで、有効活用できます。様々なツールを使って、どのように売上を上げるのか？
                        どのように業務改善するのか？どのようにしくみ化するのか？
                        </p>

                        <p className="p-mission-box--text">
                        ICTツールの本来の役割はなんでしょうか。システムを導入し「売上が上がった」「業務効率化できた」「会社がしくみで動くようになった」
                        そういっていただけると私たちは大変うれしく思います。
                        </p>

                        <p className="p-mission-box--text">
                        私たちは、ICTツールやウェブの活用方法や運用方法を中長期にわたりアドバイスさせていただくことで、
                        お客様の「成功」をサポートすることがミッションだと考えております。
                        </p>
                      </div>
                  </section>

              </div>

            </Contents>

        </>
    )    
}



export default Mission;


